<template>
    <div class="page-view" ref="pageView">
        <!-- <img src="../assets/imgs/overview-title.png" alt="" class="overview-title"> -->
        <!-- <img src="../assets/imgs/overview-title-txt.png" alt="" class="overview-title-txt"> -->
        <div class="overview-title lottie" ref="homeTitle"></div>
        <!-- <img src="../assets/imgs/home-bg.png" alt="" class="page-bg"> -->

        <router-view :key="$route.fullPath" />

        <img src="../assets/imgs/overview-bottom.png" alt="" class="page-footer">
        <div class="footer-lottie" ref="footer"></div>
    </div>
</template>

<script>
import lottie from "lottie-web"
import bodyBg from "../assets/imgs/home-bg.png"

export default {
    mounted(){
        this.setScale();
        this.initLottie();
        window.onresize = this.Debounce(this.setScale, 1000);
        this.setBg();
    },
    beforeDestroy(){
        this.clearBg();
    },
    methods:{
        setBg(){
            const body = document.body || document.documentElement.body;
            body.style.backgroundImage = `url(${bodyBg})`;
            body.style.backgroundRepeat = 'no-repeat';
            body.style.backgroundSize = '100% 100%';
            body.style.backgroundPosition = 'center';
        },
        clearBg(){
            const body = document.body || document.documentElement.body;
            body.style.backgroundImage = ``;
        },
        initLottie(){
            const header = this.$refs.homeTitle;
            const footer = this.$refs.footer;
            lottie.loadAnimation({
                container: header,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: "/static/lottie/home-title.json"
            });
            lottie.loadAnimation({
                container: footer,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: "/static/lottie/footer.json"
            });
        },
        setScale(){
            const page = this.$refs['pageView'];
            const width = document.documentElement.clientWidth;
            const height = document.documentElement.clientHeight;
            // console.log(width, height);
            const designWidth = 1920;
            const designHegiht = 1080;
            const wScale = width / designWidth;
            const hScale = height / designHegiht;
            const dom = page;
            // console.log(dom)
            const scale = wScale < hScale ? wScale : hScale;
            dom.style.width = `${designWidth}px`;
            dom.style.height = `${designHegiht}px`;
            dom.style.transform = `scale(${scale}) translate(-50%, -50%)`;
        },
        Debounce(fn, t){
            const delay = t || 500;
            let timer;
            return function () {
                const args = arguments;
                if (timer) {
                clearTimeout(timer);
                }
                const context = this;
                timer = setTimeout(() => {
                timer = null;
                fn.apply(context, args);
                }, delay);
            };
        }
    }
}
</script>

<style>

</style>
<style scoped>
body{
    /* background-image: url("../assets/imgs/home-bg.png"); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}
.page-view{
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform-origin: 0 0;
    overflow-x: hidden;
    overflow-y: hidden;
}
.overview-title{
    position: absolute;
    top: 0;
    left: 0;
    width: 1920px;
    height: 1080px;
    z-index: 10;
}
/* .overview-title-txt{
    position: absolute;
    top: 20px;
    left: 50%;
    width: 496px;
    height: 41px;
    transform: translateX(-50%);
    z-index: 11;
} */
.page-bg{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
}
.footer-lottie{
    position: absolute;
    top: 0;
    left: 0;
    width: 1920px;
    height: 1080px;
    z-index: 10;
}
.page-footer{
    position: absolute;
    left: 224px;
    bottom: -40px;
    width: 1475px;
    height: 112px;
}
</style>