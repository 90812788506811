<template>
  <div id="app">
    <ControlLayout v-if="this.$route.meta.layout==='control'"/>
    <FireworkLayout v-if="this.$route.meta.layout==='firework'"/>
    <OverView v-if="this.$route.meta.layout==='overview-v2'"/>
    <DefaultLayout v-if="this.$route.meta.layout==null"/>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {}
}
</script>
