<template>
<div id="app">
  <div id="nav" class="nav">
    <div class="title">{{currentRouteName}}</div>
  </div>
  <div class="boxes">
    <div class='box'>
      <a v-on:click="testUE">测试UE通信</a>
    </div>
    <div class='box'>
      <a v-on:click="redirectTo('动态人流分析')">动态人流</a>
    </div>
    <div class='box'>
      <a v-on:click="redirectTo('街区商铺管理')">商铺管理</a>
    </div>
    <div class='box'>
      <a v-on:click="redirectTo('街区景点管理')">景点管理</a>
    </div>
   </div>
   <div class="boxes">
     <div class="box"></div>
   </div>
   <div class="boxes">
    <div class='box'>
      <a v-on:click="redirectTo('平台预警')">平台预警</a>
    </div>
    <div class='box'>
      <a v-on:click="redirectTo('智慧设备管理')">设备管理</a>
    </div>
    <div class='box'>
      <a v-on:click="redirectTo('商圈展示')">商圈展示</a>
    </div>
   </div> 
</div>
</template>

<script>
export default {
  name: "ControlLayout",
  components: {},
  mounted() {
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    redirectTo(name) {
      this.$socket.emit("client_redirect_to", {name: name})
    },
    testUE(){
      eval("ue.interface.broadcast('version', 'test')")
    }
  },
};
</script>

<style scoped>
#app {
  font-family: SourceHanSansCN, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: hidden;
}

.nav {
  height: 10vh;
  background: #141930;
  text-align: center;
  position: relative;
  z-index: 1;
}

.nav .breadcurmb {
  margin-right: 10px;
}

.nav .breadcurmb a {
  color: #2186ac;
  text-decoration: none;
  border: 2px solid #2186ac;
  border-radius: 20px;
  padding: 6px 20px;
}

.title {
  color: #a2e9fd;
  font-size: 48px;
  line-height: 100px;
  box-shadow: 0 0 20px 10px #11233c inset;
  font-weight: bold;
}

.boxes {
  display: grid;
  float: left;
  width: 33.3%;
}
.box {
  height: 30vh;
  line-height: 30vh;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}
</style>
<style>
* {
  box-sizing: border-box;
}

body,
p {
  margin: 0;
}
</style>
