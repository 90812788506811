import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '平台预警',
    component: () => import('../views/Home.vue'),
    meta: { title: '平台预警-鲁迅故里高品质步行街5D可视化大数据仓' }
  },
  {
    path: '/business',
    name: '商圈展示',
    component: () => import('../views/Business.vue'),
    meta: { title: '商圈展示-鲁迅故里高品质步行街5D可视化大数据仓' }
  },
  {
    path: '/equipment',
    name: '智慧设备管理',
    component: () => import('../views/Equipment.vue'),
    meta: { title: '平台预警-鲁迅故里高品质步行街5D可视化大数据仓' }
  },
  {
    path: '/jingdianguanli',
    name: '街区景点管理',
    component: () => import('../views/jingdianguanli.vue'),
    meta: { title: '街区景点管理-鲁迅故里高品质步行街5D可视化大数据仓' }
  },
  {
    path: '/shangpuguanli',
    name: '街区商铺管理',
    component: () => import('../views/shangPuGuanLi.vue'),
    meta: { title: '街区商铺管理-鲁迅故里高品质步行街5D可视化大数据仓' }
  },
  {
    path: '/renliufenxi',
    name: '动态人流分析',
    component: () => import('../views/renLiuFenXi.vue'),
    meta: { title: '动态人流分析-鲁迅故里高品质步行街5D可视化大数据仓' }
  },
  {
    path: '/control',
    name: '控制终端',
    component: () => import('../views/control.vue'),
    meta: { layout: 'control' }
  },
  {
    path: '/overview',
    name: '鲁迅故里高品质步行街5D可视化大数据仓',
    component: () => import('../views/OverviewV2.vue'),
    meta: { title: '鲁迅故里高品质步行街数据驾驶舱', layout: 'overview-v2' }
  },
  {
    path: '/overview-v2',
    name: '鲁迅故里高品质步行街数据驾驶舱',
    component: () => import('../views/OverviewV2.vue'),
    meta: { title: '鲁迅故里高品质步行街数据驾驶舱', layout: 'overview-v2' }
  },
  {
    path: '/firework',
    name: '烟花',
    component: () => import('../views/Firework.vue'),
    meta: { title: 'firework', layout: 'firework' }
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

router.beforeEach((to, _, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
