<template>
  <div>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
export default {
  name: "FireworkLayout",
  components: {},
};
</script>

<style scoped>
.title {
  color: #a2e9fd;
  font-size: 48px;
  line-height: 100px;
  box-shadow: 0 0 20px 10px #11233c inset;
  font-weight: bold;
}

.boxes {
  display: grid;
  float: left;
  width: 33.3%;
}
.box {
  height: 30vh;
  line-height: 30vh;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}
</style>
<style>
* {
  box-sizing: border-box;
}

body,
p {
  margin: 0;
}
</style>
